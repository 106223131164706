import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ResponseJson } from "../models/models";
import { BaseService } from "./base.service";

@Injectable({ providedIn: 'root' })
export class OtpService extends BaseService {

    apiUrl = environment.apiUrl + '/otp'
    publicUrl = environment.apiUrl + '/public/otp'

    constructor(protected override http: HttpClient) {
        super(http)
    }

    getOtp(contactNumber: string) {
        return this.post<ResponseJson<any>>(this.apiUrl, { contactNumber })
    }

    validateOtp(contactNumber: string, otp: number) {
        return this.post<ResponseJson<any>>(this.apiUrl + '/validate', { contactNumber, otp })
    }

    masterLogin(contactNumber: string, password?: string) {
        return this.post<ResponseJson<any>>(this.publicUrl + '/master', { contactNumber, password })
    }

    sendVerificationEmail(data: any) {
        return this.post<ResponseJson<any>>(this.apiUrl + '/email/', data)
    }

    emailLogin(email: string, password: string) {
        return this.post(this.apiUrl + '/login-email', {email, password})
    }
}