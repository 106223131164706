import { IProjects } from './../models/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends BaseService {
  currentProject : BehaviorSubject<IProjects | null> = new BehaviorSubject<IProjects | null>(null)
  
  apiUrl = environment.apiUrl + '/projects'

  constructor(
    protected override http: HttpClient,
    private router : Router
  ) {
    
    super(http)
    if(sessionStorage.getItem('currentProject')) {
      const projectId : any = JSON.parse(sessionStorage.getItem('currentProject') as string)
      this.currentProject.next(projectId);
    }

   }

  updateCurrentProject(project : IProjects) {
    sessionStorage.setItem('currentProject', JSON.stringify(project));
    this.currentProject.next(project);
    this.router.navigate(['/dashboard']);
  }


  getProjectsData() {
    return this.get(this.apiUrl)
  }
}
