import { ProjectInterceptor } from './shared/interceptors/project.interceptor';
import { ProjectsGuard } from './shared/guards/projects.guard';
import { ToastrModule } from 'ngx-toastr';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './shared/guards/auth.guard';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { LoggedInGuard } from './shared/guards/logged.guard';
import { ServiceInterceptor } from './shared/interceptors/service.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { PromptComponent } from './shared/modules/prompt/prompt.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule } from '@angular/material/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    PromptComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    MatRippleModule,
    NgxDaterangepickerMd.forRoot(),

    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      maxOpened: 1,
      preventDuplicates: true,
    }),
    RouterModule.forRoot([{
      path: '',
      canActivate: [LoggedInGuard],
      loadChildren: () => import('./modules/public/layout/layout.module').then(m => m.LayoutModule)
    },
    {
      path: 'dashboard',
      canActivate: [ProjectsGuard],
      loadChildren: () => import('./modules/dashboard/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule)
    },
    {
      path: 'projects',
      canActivate: [AuthGuard],
      loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
    },
    {
      path: 'settings',
      canActivate: [AuthGuard],
      loadChildren: () => import('./modules/settings/settings-layout/settings.module').then(m => m.SettingsModule)
    }
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServiceInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProjectInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
