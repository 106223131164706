import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize, Observable } from "rxjs";
import { LoaderService } from "../services/loader.service";

@Injectable()
export class ServiceInterceptor implements HttpInterceptor {
    constructor(
        private laoderService: LoaderService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.laoderService.show()
        
        return next.handle(req).pipe(
            finalize((() => this.laoderService.hide()))
        )
    }

}