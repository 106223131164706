<div class="mb-3">
    <h3>{{ title }}</h3>
    <div [innerHTML]="message"></div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button matRipple class="btn cancel" *ngIf="showCancel" (click)="submit(promptResult.Cancel)">Cancel</button>
    <button matRipple class="btn cancel" *ngIf="showNotNow" (click)="submit(promptResult.NotNow)">Not Now</button>
    <button matRipple class="btn primary-btn" *ngIf="showOk" (click)="submit(promptResult.Ok)">Ok</button>
    <button matRipple class="btn cancel" *ngIf="showNo" (click)="submit(promptResult.No)">No</button>
    <button matRipple class="btn primary-btn" *ngIf="showYes" (click)="submit(promptResult.Yes)">Yes</button>
</div>
