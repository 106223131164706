import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { PromptComponent } from '../modules/prompt/prompt.component';
import { PromptButtonGroup, PromptResult, PromptType } from '../models/enums';

@Injectable({ providedIn: 'root' })
export class PromptService {

    constructor(public dialog: MatDialog) {

    }

    // Emits the showPromptEvent with the details.
    public showPrompt(type: PromptType, title: string, message: string | string[], buttonGroup: PromptButtonGroup,
        closeHandler?: (result: PromptResult) => void): void {

        const dialogRef = this.dialog.open(PromptComponent, {
            hasBackdrop: true,
            disableClose: true,
            data: {
                title: title,
                message: message,
                closeHandler: closeHandler,
                promptType: type,
                buttonGroup: buttonGroup
            },
            minWidth: '40%',
            position: {
                top: '4rem',
            },
            panelClass: 'prompt-dialog'
        });
    }

}
