import { Injectable } from '@angular/core';
import {
    HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private router: Router) { }



    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(res => {
            if (res instanceof HttpResponse) {
                if (!res.body.success
                    && (res.body.code === 1000)) {
                    // this.toastService.warning(res.body.message);
                }
            }
        }), catchError((err: HttpErrorResponse) => {

            if (err.status === 401) {
                // Unauthorized User
                this.toastr.error(err.error.error)
                this.authenticationService.logout()
            } else if (err.status === 403) {
                // Unauthenticated User
                this.toastr.error("You don't have required permissions, Please contact your System Administrator in order to request permissions", err.error.message)
                // this.router.navigate([''])
            } else if (err.status === 500) {
                // responses for the family of internal server errors
                this.toastr.error(err.error.error)
            }
            return throwError(() => new Error(err.message))
        }));
    }

    public reRouteToLoginPage(): void {
        this.authenticationService.logout();
        setTimeout(() => {
            this.router.navigate(['']);
        });
    }

}


