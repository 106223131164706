import { ProjectsService } from './../services/projects.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({ providedIn: 'root' })
export class ProjectsGuard  {

    constructor(
        private router: Router,
        private projectsService : ProjectsService,
        private authService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.projectsService.currentProject.value && this.authService.currentUser.value) {
            return true
        } else {
            this.router.navigate(['/projects']);
            return false
        }
    }
}
