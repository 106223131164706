import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PromptButtonGroup, PromptInfo, PromptResult, PromptType, ThemeType } from '../../models/enums';
import { PromptService } from '../../services/prompt.service';

@Component({
  selector: 'crm-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {

  @ViewChild('modalDialog', { static: true }) public modalDialog!: ElementRef;

  @ViewChild('closeButton', { static: true }) public closeButton!: ElementRef;

  public showModal = false; // add .show bootstrap class after a delay for animation

  public displayModal = false; // show or hide modal


  @Optional() private closeHandler!: (returnedData?: any) => void;

  public title = '';
  public readonly promptResult = PromptResult;

  public message = '';
  public promptType: PromptType = PromptType.Success;
  public theme: ThemeType = ThemeType.Success;
  public showOk = false;
  public showYes = false;
  public showNo = false;
  public showCancel = false;
  public showNotNow = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PromptComponent>,
  ) {

  }

  ngOnInit(): void {
    this.theme = this.getThemeByType(this.data.promptType);
    this.showPrompt(this.data);
  }


  /**
    * showPrompt
    * @param {PromptType} promptType
    * @param {any} message
    * @param {PromptButtonGroup} buttonGroup
    * @param {string} buttonYesCaption
    * @param {string} buttonNoCaption
   */
  public showPrompt(promptInfo: PromptInfo): void {
    this.promptType = promptInfo.promptType;
    this.closeHandler = promptInfo.closeHandler;
    this.displayButtons(promptInfo.buttonGroup);
    this.title = promptInfo.title;
    if (Array.isArray(promptInfo.message)) {
      this.message = promptInfo.message.map((msg: any, index: any) => ((index + 1) + '.' + msg)).join('\n\n');
    } else {
      this.message = promptInfo.message;
    }
  }


  /**
     * displayButtons
     * @param {PromptButtonGroup} buttonGroup
    */
  private displayButtons(buttonGroup: PromptButtonGroup): void {
    switch (buttonGroup) {

      case PromptButtonGroup.Ok: {
        this.showOk = true;
        this.showYes = false;
        this.showNo = false;
        this.showCancel = false;
        this.showNotNow = false;
        break;
      }
      case PromptButtonGroup.OkCancel: {
        this.showOk = true;
        this.showYes = false;
        this.showNo = false;
        this.showCancel = true;
        this.showNotNow = false;
        break;
      }
      case PromptButtonGroup.YesNo: {
        this.showOk = false;
        this.showYes = true;
        this.showNo = true;
        this.showCancel = false;
        this.showNotNow = false;
        break;
      }
      case PromptButtonGroup.YesNoCancel: {
        this.showOk = false;
        this.showYes = true;
        this.showNo = true;
        this.showCancel = true;
        this.showNotNow = false;
        break;
      }
      case PromptButtonGroup.SaveCancel: {
        this.showOk = false;
        this.showYes = false;
        this.showNo = false;
        this.showCancel = true;
        this.showNotNow = false;
        break;
      }
      case PromptButtonGroup.SaveReleaseCancel: {
        this.showOk = false;
        this.showYes = false;
        this.showNo = false;
        this.showCancel = true;
        this.showNotNow = false;
        break;
      }
      case PromptButtonGroup.Ok_NotNow_Cancel: {
        this.showOk = true;
        this.showYes = false;
        this.showNo = false;
        this.showCancel = true;
        this.showNotNow = true;
        break;
      }
      default:
        throw new Error('PromptButtonGroup must be implemented');
    }
  }

  /**
    * used to close the dialog using the passed in result
    * @param {PromptResult} result
    */
  public submit(result: PromptResult): void {
    this.close(result);
  }

  /*
     To get the theme based on promptType
 */
  private getThemeByType(type: number): ThemeType {
    switch (type) {
      case 0:
        return ThemeType.Danger;
      case 1:
        return ThemeType.Info;
      case 2:
        return ThemeType.Warning;
      case 3:
        return ThemeType.Warning;
      case 4:
        return ThemeType.Success;
      default:
        return ThemeType.Info;
    }
  }

  /**
    * Need to clean up the control after the dialog is closed to ensure the focus event on the buttons is always executed
   */
  private cleanUp(): void {
    this.showOk = false;
    this.showYes = false;
    this.showNo = false;
    this.showCancel = false;
    this.showNotNow = false;
    this.message = '';
    this.title = '';
  }

  public close = (data?: any) => {

    this.dialogRef.close();
    
    this.cleanUp();

    if(this.closeHandler) {
      this.closeHandler(data);
    }
  }
}
