import { BehaviorSubject } from "rxjs";
import jwt_decode from 'jwt-decode';
import { Injectable } from "@angular/core";
import { OtpService } from "./otp.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IJwtTokenUser, IUser } from "../models/models";
import { PromptService } from "./prompt.service";
import { PromptButtonGroup, PromptResult, PromptType } from "../models/enums";
import { ProjectsService } from "./projects.service";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    currentUser: BehaviorSubject<IJwtTokenUser | null> = new BehaviorSubject<IJwtTokenUser | null>(null)
    currentProject!: string

    constructor(
        private otpService: OtpService,
        private router: Router,
        private route: ActivatedRoute,
        private promptService: PromptService,
        private projectService: ProjectsService
    ) {
        const user = JSON.parse(localStorage.getItem('currentUser') as string)
        this.currentProject = this.projectService.currentProject?.value?._id as string
        if (user) {
            this.currentUser.next(user)
        }
    }

    loginUser(jwtToken: string) {
        const user = jwt_decode(jwtToken)
        localStorage.setItem('token', jwtToken)
        localStorage.setItem('currentUser', JSON.stringify(user))
        this.currentUser.next(user as IJwtTokenUser)
    }

    login(contactNumber: string, otp: number) {
        return new Promise((resolve,reject) => {
            this.otpService.validateOtp(contactNumber, otp).subscribe({
                next: (res: any) => {
                    if (res.success) {
                        this.loginUser(res.data.token)
                        const returnUrl = this.route.snapshot.queryParams['returnUrl']
                        if (returnUrl && this.currentProject) {
                            this.router.navigate([returnUrl])
                        } else {
                            this.router.navigate(['/projects'])
                        }
                    }
                },
                error: (res) => { reject(res) }
            })
        })
    }

    masterLogin(contactNumber: string, password?: string) {
        return new Promise((resolve, reject) => {
            this.otpService.masterLogin(contactNumber, password).subscribe({
                next: (res: any) => {
                    if (res.success) {
                        this.loginUser(res.data.token)
                        const returnUrl = this.route.snapshot.queryParams['returnUrl']
                        if (returnUrl && this.currentProject) {
                            this.router.navigate([returnUrl])
                        } else {
                            this.router.navigate(['/projects'])
                        }
                    }
                },
                error:(res) => { reject(res) }
            })
        })
    }

    loginViaEmail(email: string, password: string) {
        return new Promise((resolve, reject) => {
            this.otpService.emailLogin(email.toLocaleLowerCase(), password).subscribe({
                next: (res: any) => {
                    if (res.success) {
                        this.loginUser(res.data.token)
                        const returnUrl = this.route.snapshot.queryParams['returnUrl']
                        if (returnUrl && this.currentProject) {
                            this.router.navigate([returnUrl])
                        } else {
                            this.router.navigate(['/projects'])
                        }
                    }
                }, error: (res) => { reject(res) }
            })
        })
    }

    logoutWithPopup() {
        this.promptService.showPrompt(
            PromptType.Information,
            'Confirmation', 'Are you sure you want to logout ?',
            PromptButtonGroup.YesNo,
            promptResult => {
                if (promptResult === PromptResult.Yes) {
                    this.logout()
                }
            }
        );
    }

    logout() {
        localStorage.removeItem('currentUser')
        this.currentUser.next(null)
        this.router.navigate(['/'])
        window.location.reload()
    }

}
