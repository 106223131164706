/**
 * PromptType enum
 */
export enum PromptType {
    Error = 0,
    Information = 1,
    Question = 2,
    Warning = 3,
    Success = 4
}

/**
* Enumeration PromptResult
*/
export enum PromptResult {
    Ok = 0,
    Cancel = 1,
    Yes = 2,
    No = 3,
    Save = 4,
    Release = 5,
    NotNow = 6
}
export interface PromptInfo {
    promptType: PromptType;
    title: string;
    message: string;
    closeHandler: (result: PromptResult) => void;
    buttonGroup: PromptButtonGroup;
}


export enum CURRENCY_INPUT_TYPES {
    SALARY = 'SALARY',
    INCENTIVE = 'INCENTIVE',
    EXISTING_LOAN = 'EXISTING_LOAN',
    OTHER_INCOME = 'OTHER_INCOME',
    LPA='LPA'
}

/**
* PromptButtonGroup enum
*/
export enum PromptButtonGroup {
    Ok = 0,
    OkCancel = 1,
    YesNo = 2,
    YesNoCancel = 3,
    SaveCancel = 4,
    SaveReleaseCancel = 5,
    Ok_NotNow_Cancel = 6
}

export enum ThemeType {
    Primary = 'primary',
    Default = 'default',
    Inverse = 'inverse',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Danger = 'danger',
    Raised = 'raised',
    Received = 'received',
    LiteSuccess = 'lite_success',
    LiteDanger = 'lite_danger',
    Inactive = 'in_active'
}

export enum USER_TYPE {
    CRM = 'CRM',
    BRAND = 'BRAND',
    SYS_ADMIN = 'SYS_ADMIN',
    CUSTOMER = 'CUSTOMER'
}

export enum EXEC_TYPE {
    SYS_ADMIN = 'SYS_ADMIN',
    EXECUTIVE = 'EXECUTIVE'
}

export enum SALUTATION {
    MR = 'Mr.',
    MRS = 'Mrs.',
    DR = 'Dr.',
    MS = 'Ms.'
}

export enum CUSTOMER_RELATION_TYPE {
    SO = 'S/o.',
    DO = 'D/o.',
    WO = 'W/o.'
}


export enum COLLECTION_LOG_STATUS {
    PENDING = 'Pending',
    GENERATED = 'Generated',
    SUSPENSE = 'Suspense',
    CANCELLED = 'Cancelled',
    SHARED = 'Shared',
}
export enum LEADS_PAYMENT_TYPES {
    CHEQUE = 'CHEQUE',
	PAY_U = 'PAY_U',
	ACCOUNT = 'ACCOUNT'
}

