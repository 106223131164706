import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUser.value
        const token = localStorage.getItem('token')
        if(currentUser) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    //currentUser: `${currentUser._id}`
                }
            })
        }
        return next.handle(request)
    }

}
