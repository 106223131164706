import { ProjectsService } from './../services/projects.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class ProjectInterceptor implements HttpInterceptor {

    constructor(
        private projectService: ProjectsService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if(this.projectService.currentProject.value) {
            const currentProject = this.projectService.currentProject.value;
            req = req.clone({
                setHeaders: {
                    projectId: `${currentProject._id}`,
                }
            })
        }
        return next.handle(req);
    }
}